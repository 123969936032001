import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { ChatContextProvider } from './context/ChatContext';
import { SidebarContextProvider } from './context/SidebarContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AuthContextProvider>
		<ChatContextProvider>
			<SidebarContextProvider>
				<>
					<App />
				</>
			</SidebarContextProvider>
		</ChatContextProvider>
	</AuthContextProvider>
);
