import React, { useContext, useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';
import { SidebarContext } from '../context/SidebarContext';

const Chats = () => {
	const [chats, setChats] = useState([]);
	const { currentUser } = useContext(AuthContext);
	const { dispatch } = useContext(ChatContext);
	const { setShowSidebar, isMobile } = useContext(SidebarContext);

	useEffect(() => {
		const getChats = () => {
			const unsub = onSnapshot(doc(db, 'userChats', currentUser.uid), (doc) => {
				setChats(doc.data());
			});

			return () => {
				unsub();
			};
		};

		currentUser.uid && getChats();
	}, [currentUser.uid]);

	const handleSelect = (u) => {
		dispatch({ type: 'CHANGE_USER', payload: u });
		isMobile && setShowSidebar(false);
	};

	return (
		<div className="chats">
			{chats &&
				Object.entries(chats)
					?.sort((a, b) => b[1].date - a[1].date)
					.map((chat) => (
						<div
							className="userChat"
							key={chat[0]}
							onClick={() => handleSelect(chat[1].userInfo)}
						>
							<img src={chat[1].userInfo.photoURL} alt="" />
							<div className="userChatInfo">
								<span>{chat[1].userInfo.displayName}</span>
								<p>
									{chat[1].lastMessage?.text &&
										(chat[1].lastMessage?.text.length < 30
											? chat[1].lastMessage?.text
											: chat[1].lastMessage?.text.slice(0, 30) + '...')}
								</p>
							</div>
						</div>
					))}
		</div>
	);
};

export default Chats;
