import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';

const Message = ({ message }) => {
	const { currentUser } = useContext(AuthContext);

	const ref = useRef();

	useEffect(() => {
		ref.current?.scrollIntoView({ behavior: 'instant' });
	}, [message]);

	const convertTime = (time) =>
		time.toDate().toLocaleTimeString(navigator.language, {
			hour12: false,
			hour: '2-digit',
			minute: '2-digit',
		});

	return (
		<div
			ref={ref}
			className={`message ${message.senderId === currentUser.uid && 'owner'}`}
		>
			<div className="messageContent">
				<p>{message.text}</p>
				{message.img && <img src={message.img} alt="" />}
				<span>{convertTime(message.date)}</span>
			</div>
		</div>
	);
};

export default Message;
