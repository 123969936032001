import { createContext, useEffect, useState } from 'react';

export const SidebarContext = createContext();

export const SidebarContextProvider = ({ children }) => {
	const [showSidebar, setShowSidebar] = useState(true);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			window.innerWidth >= 811 ? setShowSidebar(true) : setShowSidebar(false);
			window.innerWidth >= 811 ? setIsMobile(false) : setIsMobile(true);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		window.innerWidth >= 811 ? setIsMobile(false) : setIsMobile(true);
	}, []);

	return (
		<SidebarContext.Provider
			value={{ showSidebar, setShowSidebar, isMobile, setIsMobile }}
		>
			{children}
		</SidebarContext.Provider>
	);
};
