import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: 'firechat-b0341.firebaseapp.com',
	projectId: 'firechat-b0341',
	storageBucket: 'firechat-b0341.appspot.com',
	messagingSenderId: '923609408036',
	appId: '1:923609408036:web:9d1ab014483c8d31ef6eb1',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
