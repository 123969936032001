import Sidebar from '../components/Sidebar';
import Chat from '../components/Chat';
import { useContext, useEffect, useState } from 'react';
import { SidebarContext } from '../context/SidebarContext';

const Home = () => {
	const { showSidebar, isMobile } = useContext(SidebarContext);
	const [showChat, setShowChat] = useState(true);

	useEffect(() => {
		if (isMobile && showSidebar) {
			setShowChat(false);
		} else if (isMobile && !showSidebar) {
			setShowChat(true);
		} else {
			setShowChat(true);
		}
	}, [showSidebar, isMobile]);

	return (
		<div className="home">
			<div className="container">
				{showSidebar && <Sidebar />}
				{showChat && <Chat />}
			</div>
		</div>
	);
};

export default Home;
