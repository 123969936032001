import React, { useContext, useEffect, useState } from 'react';
import {
	collection,
	query,
	where,
	getDocs,
	setDoc,
	doc,
	updateDoc,
	serverTimestamp,
	getDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../context/AuthContext';

const Search = () => {
	const [username, setUsername] = useState('');
	const [user, setUser] = useState(null);
	const [err, setErr] = useState(false);

	const { currentUser } = useContext(AuthContext);

	useEffect(() => {
		if (username[0] != undefined) {
			handleSearch();
		} else {
			setUser(null);
		}
	}, [username]);

	const handleSearch = async () => {
		const q = query(
			collection(db, 'users'),
			where(
				'displayName',
				'<=',
				username.charAt(0).toUpperCase() + username.slice(1) + '\uf8ff'
			),
			where(
				'displayName',
				'>=',
				username.charAt(0).toUpperCase() + username.slice(1)
			)
		);

		try {
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				setUser(doc.data());
			});
		} catch (err) {
			setErr(true);
		}
	};

	const handleSelect = async () => {
		//check whether the group(chats in firestore) exists, if not create
		const combinedId =
			currentUser.uid > user.uid
				? currentUser.uid + user.uid
				: user.uid + currentUser.uid;
		try {
			const res = await getDoc(doc(db, 'chats', combinedId));

			if (!res.exists()) {
				//create a chat in chats collection
				await setDoc(doc(db, 'chats', combinedId), { messages: [] });

				//create user chats
				await updateDoc(doc(db, 'userChats', currentUser.uid), {
					[combinedId + '.userInfo']: {
						uid: user.uid,
						displayName: user.displayName,
						photoURL: user.photoURL,
					},
					[combinedId + '.date']: serverTimestamp(),
				});

				await updateDoc(doc(db, 'userChats', user.uid), {
					[combinedId + '.userInfo']: {
						uid: currentUser.uid,
						displayName: currentUser.displayName,
						photoURL: currentUser.photoURL,
					},
					[combinedId + '.date']: serverTimestamp(),
				});
			}
		} catch (err) {
			setErr(true);
		}

		setUser(null);
		setUsername('');
	};

	return (
		<div className="search">
			<div className="searchForm">
				<svg
					className="search-icon"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
					<g
						id="SVGRepo_tracerCarrier"
						strokeLinecap="round"
						strokeLinejoin="round"
					></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
							stroke="#9fa3af"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
					</g>
				</svg>
				<input
					type="text"
					placeholder="Find a user"
					onChange={(e) => {
						setUsername(e.target.value);
					}}
					value={username}
				/>
			</div>
			{err && <span>User not found!</span>}
			{user && (
				<div className="userChat" onClick={handleSelect}>
					<img src={user.photoURL} alt="" />
					<div className="userChatInfo">
						<span>{user.displayName}</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default Search;
