import React, { useContext } from 'react';

import Messages from './Messages';
import Input from './Input';
import Back from '../img/back.svg';

import { ChatContext } from '../context/ChatContext';
import { SidebarContext } from '../context/SidebarContext';

const Chat = () => {
	const { data } = useContext(ChatContext);
	const { setShowSidebar } = useContext(SidebarContext);

	const handleClick = (e) => {
		e.preventDefault();
		setShowSidebar(true);
	};

	return (
		<div className="chat">
			<div className="chatInfo">
				<img className="backButton" src={Back} alt="" onClick={handleClick} />
				{data.user.photoURL && (
					<img className="chat-img" src={data.user.photoURL} alt="" />
				)}
				<span>{data.user.displayName}</span>
			</div>
			<Messages />
			<Input />
		</div>
	);
};

export default Chat;
